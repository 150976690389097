<template>
<div class="guid-container">
    <div class="search_top">
        <topHeader></topHeader>
    </div>
    <div class="container">
        <h1>用户权益说明</h1>
        <div class="guide-content">
            <div class="left-menu">
                <ul>
                    <li @click="changeParams('guid-account')" :class="showId === 'guid-account' ? 'active' : ''">账户充值</li>
                    <li @click="changeParams('guid-level')" :class="showId === 'guid-level' ? 'active' : ''">用户等级</li>
                    <li @click="changeParams('guid-vip')" :class="showId === 'guid-vip' ? 'active' : ''">VIP权益</li>
                    <li @click="changeParams('guid-reward')" :class="showId === 'guid-reward' ? 'active' : ''">道具打赏</li>
                    <li @click="changeParams('guid-ticket')" :class="showId === 'guid-ticket' ? 'active' : ''">推荐票制度</li>
                    <li @click="changeParams('guid-fans')" :class="showId === 'guid-fans' ? 'active' : ''">粉丝成长值</li>
                </ul>
            </div>
            <div class="desc">
                <div ref="guid-account">
                    <h2>一、账户充值</h2>
                    <p>
                        "鲸云阅读"的VIP会员服务需要使用本站专用虚拟货币——鲸币，以下是具体币值说明与兑换方式，书友们可以通过向本站的帐户充值，来享受本站所提供的一切VIP会员服务。<br/>
                        充值兑换比例：人民币1元 = 100鲸币
                    </p>
                </div>
                <div ref="guid-level">
                    <h2>二、用户等级</h2>
                    <p>
                        1、充值金额：<br/>
                        10元=1000鲸币 30元=3000鲸币 50元=5000鲸币 100元=10000鲸币<br/>
                        200元=20000鲸币 300元=30000鲸币 其他自定义金额：XX元<br/>
                    </p>
                    <p>2、读者等级特权</p>
                    <img src="./../../assets/images/guid/level.png" alt="">
                </div>
                <div ref="guid-vip">
                    <h2>三、VIP权益</h2>
                    <p>
                        包月鲸云会员卡 8元/月<br/>
                        1、包月书库免费畅读。<br/>
                        2、能获得专属装扮。<br/>
                        郑重说明：鲸云阅读的包月作品，读者只能在鲸云官网阅读，不得擅自转载，公开在其它站传播，一经发现轻则删除帐户，重则交由公安部门处理。
                    </p>
                </div>
                <div ref="guid-reward">
                    <h2>四、道具打赏</h2>
                    <p>
                        1、打赏道具<br/>
                        肥宅水 100鲸币 ，催更刀片 888鲸币，提神咖啡 1314鲸币 ，灵感寿司 2333鲸币，生花金笔 6666鲸币 ，炫光大键盘 9999鲸币 ，鲸云娘的码字电脑 50000鲸币，鲸云娘的码字按摩椅 100000鲸币，鲸云娘的甜蜜城堡1000000鲸币
                        <br/>2、打赏道具读者升级昵称
                    </p>
                </div>
                <div ref="guid-ticket">
                    <h2>五、推荐票制度</h2>
                    <p>
                        1.推荐票是什么？<br/>
                        鲸云的推荐票以获得条件严格而著称，每一张推荐票都十分珍贵，并且表达了读者对于作者莫大的支持。<br/>
                        推荐票针对高级用户系统自动发放，不同级别的用户获取的推荐票张数不同，系统发送的推荐票每月月初发放，月末清零。<br/>
                        2.推荐票有什么用？<br/>
                        推荐票是用来投给自己喜欢的作品，表示了对作品的支持和鼓励。对于作者来说，推荐票是作品受到大众欢迎的一种肯定。同时鲸云施行了推荐票奖，会根据作品每个月的推荐票数排名而发放现金奖励。<br/>
                        3.如何获得推荐票？<br/>
                        (1) 根据小说读者特权用户每月系统自动发送。<br/>
                        (2) 打赏礼物满额增票。<br/>
                        (3)浏览小说满15分钟有几率随机获得，浏览小说满30分钟一定能获得一张推荐票（每个月只能获得一张）。
                    </p>
                </div>
                <div ref="guid-fans">
                    <h2>六、粉丝成长值</h2>
                    <p>
                        1、当您对小说A产生消费或投推荐票时，便能获得对应于A小说的粉丝值。<br/>
                        2、订阅VIP小说章节，每消费1鲸币获得1粉丝值。<br/>
                        3、推荐票，每票获得200粉丝值。<br/>
                        4、打赏作者，每消费1鲸币获得1粉丝成长值。<br/>
                        5、被作者加精书评，获得200粉丝值，收藏本书,获得50粉丝值。<br/>
                        6、为本书评分,获得100粉丝值。<br/>
                        7、给小说点赞，获得50粉丝值。<br/>
                        8、优秀作者可自定义粉丝称号，通用粉丝称号如下：
                    </p>
                    <img src="./../../assets/images/guid/fans.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import topHeader from "@/components/main/top-header";
    export default {
        name: "guid",
        components: {
            topHeader
        },
        data(){
            return {
                text: sessionStorage.getItem('search_val') || '',
                showId: this.$route.query.id
            }
        },
        mounted(){
            this.$refs[this.$route.query.id].scrollIntoView();
        },
        beforeMount(){
        },
        watch: {
            '$route.query.id': function(newval){
                this.$refs[newval].scrollIntoView();
                this.showId = newval;
            }
        },
        methods: {
            changeParams(id){
                this.$router.push({
                    path: "/guid",
                    query: {
                        id: id,
                    },
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.guid-container{
    min-height: 100vh;
    background: #F2F2F2;
    .search_top{
        background-color: #fff;
    }
    h1{
        padding: 25px 33px;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        color: #333333;
    }
    .guide-content{
        display: flex;
        font-size: 18px;
        .left-menu{
            width: 233px;
            min-width: 233px;
            padding: 13px 0;
            min-height: 80vh;
            max-height: 80vh;
            line-height: 40px;
            font-size: 18px;
            border-radius: 5px;
            background-color: #fff;
            ul{
                list-style: none;
                text-align: center;
                li{
                    position: relative;
                    cursor: pointer;
                    &.active{
                        &:before{
                            content: '';
                            display: inline-block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 6px;
                            height: 40px;
                            background: #409EFF;
                        }
                    }
                }
            }
        }
        .desc{
            margin-left: 10px;
            margin-bottom: 20px;
            padding: 25px 45px;
            flex-grow: 2;
            background-color: #fff;
            border-radius: 5px;
            h2{
                font-size: 18px;
                font-weight: bold;
                line-height: 24px;
                color: #333333;
            }
            p{
                margin: 20px 0;
            }
            img{
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}
</style>
